// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-blog-article-template-js": () => import("./../../../src/components/blogArticleTemplate.js" /* webpackChunkName: "component---src-components-blog-article-template-js" */),
  "component---src-components-markdown-template-js": () => import("./../../../src/components/markdownTemplate.js" /* webpackChunkName: "component---src-components-markdown-template-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-buchen-js": () => import("./../../../src/pages/buchen.js" /* webpackChunkName: "component---src-pages-buchen-js" */),
  "component---src-pages-buchung-abgeschlossen-js": () => import("./../../../src/pages/buchung-abgeschlossen.js" /* webpackChunkName: "component---src-pages-buchung-abgeschlossen-js" */),
  "component---src-pages-hatha-yoga-intense-js": () => import("./../../../src/pages/hatha-yoga-intense.js" /* webpackChunkName: "component---src-pages-hatha-yoga-intense-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-kurse-js": () => import("./../../../src/pages/kurse.js" /* webpackChunkName: "component---src-pages-kurse-js" */),
  "component---src-pages-online-js": () => import("./../../../src/pages/online.js" /* webpackChunkName: "component---src-pages-online-js" */),
  "component---src-pages-power-yoga-js": () => import("./../../../src/pages/power-yoga.js" /* webpackChunkName: "component---src-pages-power-yoga-js" */),
  "component---src-pages-studio-js": () => import("./../../../src/pages/studio.js" /* webpackChunkName: "component---src-pages-studio-js" */)
}

